.root {
  align-items: center;
  flex-direction: column;
  display: flex;
}

.loadingContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;

  display: inline-block;

  img {
    padding-bottom: 8px;
    width: 134px;
    height: 134px;
  }
}
.grid{
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
}

.gridRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 4.8vh;
  &.start{
    align-self: flex-start;
  }
  &.center{
    align-self: center;
  }
}

.sentiment {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.supLogoContainer {
  align-self: flex-end;
  margin-right: 1em;
  .supLogo{
    height: 9.6vh;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
