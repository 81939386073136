@use "sass:map";

$font: 'Roboto', sans-serif;
$large: (
  'feedbackButton': 20vh,
  'feedbackIcon': 15vh,
  'feedbackText': 0.8em,
  'margin': 10px,
);
$small: (
  'feedbackButton': 15vh,
  'feedbackIcon': 10vh,
  'feedbackText': 0.6em,
  'margin': 10px,
);
$tiny: (
  'feedbackButton': 8vh,
  'feedbackIcon': 6vh,
  'feedbackText': 0.1em,
  'margin': 2px,
);

.feedbackButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}
.feedbackButton:active {
  box-shadow: 1px 1px 10px 1px #ccc;
  opacity: 0.8;
}
.feedbackButton.disabled:active {
  box-shadow: 0 0 0 0 #ccc;
  opacity: 1;
}
.feedbackButton.large {
  height: map-get($large, 'feedbackButton');
  width: map-get($large, 'feedbackButton');
  border-radius: map-get($large, 'feedbackButton') / 10;
  margin: map-get($large, 'margin');
}
.feedbackButton.small {
  height: map-get($small, 'feedbackButton');
  width: map-get($small, 'feedbackButton');
  border-radius: map-get($small, 'feedbackButton') / 10;
  margin: map-get($small, 'margin');
}
.feedbackButton.tiny {
  height: map-get($tiny, 'feedbackButton');
  width: map-get($tiny, 'feedbackButton');
  border-radius: map-get($tiny, 'feedbackButton') / 10;
  margin: map-get($tiny, 'margin');
}
.feedbackTextContainer{
    text-align: center;
}
.feedbackTextContainer.large {
    max-width: map-get($large, 'feedbackButton')-3vh;
    margin-top: 3vh;
}
.feedbackTextContainer.small {
    max-width: map-get($small, 'feedbackButton')-2vh;
    margin-top: 2vh;
}
.feedbackTextContainer.tiny {
    max-width: map-get($tiny, 'feedbackButton')-2vh;
}
.feedbackText {
  font-family: $font;
  font-weight: 500;
}
.feedbackText.large {
  font-size: map-get($large, 'feedbackText');
}
.feedbackText.small {
  font-size: map-get($small, 'feedbackText');
}
.feedbackText.tiny {
  font-size: map-get($tiny, 'feedbackText');
}
.feedbackIconContainer {
 padding: 10px;
}
.feedbackIcon.large {
  height: map-get($large, 'feedbackIcon')-5vh;
}
.feedbackIcon.small {
  height: map-get($small, 'feedbackIcon')-3vh;
}
.feedbackIcon.tiny {
  height: map-get($tiny, 'feedbackIcon');
}
