.ackSentimentText {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  padding-top: 37px;
  margin-left: 16px;
  margin-right: 16px;
}

.ackServiceRequestText {
  @extend .ackSentimentText;
  padding-top: 113px;
}

.feedbackContainer {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.feedbackTile{
 margin-left: 5px;
}
