@use "sass:map";

$font: 'Roboto', sans-serif;

$large: (
  'sentimentImage': 22.5vh,
);
$small: (
  'sentimentImage': 12vh,
);
$tiny: (
  'sentimentImage': 7.5vh,
);

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sentimentText {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
    font-family: $font;
    font-weight: 500;
    font-size: 1em;
    text-align: 'center';
    color: #000;
    margin-bottom: 0.5em;
}

.sentimentImageContainer {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
    .large {
        height: map-get($large, "sentimentImage")
    }
    .small {
        height: map-get($small, "sentimentImage")
    }
    .tiny {
        height: map-get($tiny, "sentimentImage");
        margin: 2px;
    }
}
