$textBackgroundColor: #ECECEC;

.backButton {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.backButtonLeaveFeedback {
    @extend .backButton;
    margin-top: 12vh;
}

.selectedOptionsLeaveFeedback {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-top: 5vh;
    margin-bottom: 8vh;
}

.selectedOptions{
    position: absolute;
    top: 4vw;
    left: 4vw;
    display: flex;
    flex-direction: row;
}
.additionalFeedbackPageRoot {
    max-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.inputText{
    height: 30vh;
    width: 85vw;
}
.inputTextContainer{
    align-self: center;
    margin-bottom: 5vh;
}
textarea {
    background-color: $textBackgroundColor;
    outline: none;
    border-width:0px;
    border:none;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1em;
    resize: none;
}
