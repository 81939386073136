.serviceRequestPageContainer {
    max-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.feedbackOptionsContainerColumn {
    height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: scroll;
}
.feedbackOptionsContainerColumnCenterAlignment {
    @extend .feedbackOptionsContainerColumn;
    align-content: center;
}
.feedbackOptionsContainerRow {
    flex-direction: row;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backButton {
    align-self: center;
}
.selectedOptions{
    position: absolute;
    top: 4vw;
    left: 4vw;
    display: flex;
    flex-direction: row;
}
