.root {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 25vh;
}

.headerContainer {
    display: flex;
    position: relative;
    justify-content: center;
    height: 7.2vh;
    margin: 0 0.5em;
}

.locationContainer {
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.locationContainerWithMargin {
  @extend .locationContainer;
  margin-left: 14vw;
  max-width: 32vw;
}

.locationImage {
  height: 18px;
}

.locationText {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1em;
  color: #000;
  opacity: 0.3;
  margin-left: 1.3vw;
}
.headingContainer {
  display: flex;
  justify-content: center;
  margin-top: 6.6vh;
  text-align: center;
}

.headingText {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.25em;
  color: #000;
}

.logoContainer {
  position: absolute;
  right: 0;
}

.logoImage {
  width: 15vw;
}
