$font: 'Roboto', sans-serif;
$border-color: black;
$white-text-color: white;

.button {
    display: flex;
    flex-direction: row;
    height: 9vh;
    width: 80vw;
    border-radius: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 15px;
}
.button.border{
    border-width: 2px;
    border-color: $border-color;
    border-style: solid;
}
.button.small{
  width: 40vw !important;
  margin: 8px !important;
}
.button:active {     
    opacity: 0.8;
}
.text {
  font-size: 1em;
  font-family: $font;
  font-weight: 500;
}
.text.white {
  color: $white-text-color;
}
.icon {
  height: 18px;
}
.left-margin {
  margin-left: 10px;
}
.right-margin {
  margin-right: 10px;
}
