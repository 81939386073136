.errorPageContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;

  min-width: 250px;

  img {
    width: 134px;
    height: 134px;
    padding-bottom: 8px;
  }

  a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    text-decoration-line: underline;

    /* Dimeo Blue */
    color: #1a74bb;
  }
}
